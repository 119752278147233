// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@include mat.core();

// defines $custom-palette cu-theme styles
@import './app/shared/theme/_palette.scss';
// defines $custom-typography styles
@import './app/shared/theme/_typography.scss';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat.core($custom-typography);
// @include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$styles-primary-color: mat.m2-define-palette($cu-theme-primary);
$styles-accent-color: mat.m2-define-palette($cu-theme-secondary);

// The warn palette is optional (defaults to red).
$styles-warn-color: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$styles-theme: mat.m2-define-light-theme((color: (primary: $styles-primary-color,
        accent: $styles-accent-color,
        warn: $styles-warn-color,
      ),
      typography: $custom-typography,
    ));


// Dark Theme Palette
$dark-theme-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$dark-theme-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$dark-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);

$dark-theme: mat.m2-define-dark-theme((color: (primary: $dark-theme-primary,
        accent: $dark-theme-accent,
        warn: $dark-theme-warn,
      ),
      typography: $custom-typography,
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($styles-theme);

// Use the default configuration.
// $custom-typography: mat.define-typography-config();
@include mat.typography-hierarchy($custom-typography);

/* You can add global styles to this file, and also import other style files */

.dark-theme {
  @include mat.all-component-colors($dark-theme);
}

// this has been compiled and put in base.1.css
@import './app/shared/theme/_variables.scss';
@import "./app/shared/theme/_styles";
@import "./app/shared/theme/_flex";
@import "./app/shared/theme/_spacing";
@import './theme/_custom.scss';
