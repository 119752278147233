/* accessability */
ol,
P {
  line-height: 1.5;
}

.bold {
  font-weight: bold;
}

.color-white {
  color: white;
}

.color-black {
  color: black !important
}

details>summary {
  cursor: pointer;
  font-weight: bold;
}

details>p {
  padding-left: 20px;
  margin-top: 10px;
}

/* Style the summary when details are open */
// details[open]>summary {
//   color: #333;
// }

/* overlay slide image wrapper */
.main-wrapper {
  background-color: white
}

.home .main-wrapper,
.detail .main-wrapper {
  background-color: transparent
}

header#cu-header {
  // background: transparent !important;
  border-color: transparent !important;
  background: #222 !important;
  background: rgba(11, 35, 37, 0.5) !important;

  background: linear-gradient(to bottom, rgba(34, 34, 34, 0.9) 0%, rgba(34, 34, 34, 0.6) 100%) !important;
}

nav#main-navigation {
  // background: transparent !important;
  background: #35444f !important;
  background: rgba(53, 68, 79, 0.3) !important;
  background: linear-gradient(to bottom, rgba(34, 34, 34, 0.9) 0%, rgba(34, 34, 34, 0.6) 100%) !important;
}

.detail #main-content,
.home #main-content {
  border: none !important;
  background-color: #1c2126 !important;
}

// That annoying border
#main-content {
  border: none !important;
}

.cu-unit {
  color: white !important;

  h3 {
    // color: #9eff5d !important;
    color: #4b7b2b !important;
    // color: white !important;
    // text-transform: uppercase !important;
    // font-weight: bold !important;
    // z-index: 1000;
  }
}

.cu-unit h3.desktop-only {
  color: white !important;
}

.page-heading-tint {
  background-color: rgba(0, 0, 0, 0.1);
}

a.scl-link:visited,
app-content a:visited {
  // color: inherit !important;
  color: #1f6689 !important;
}

a.scl-link,
app-content a {
  // color: inherit !important;
  color: #1f6689 !important;
}

a.scl-link:focus,
a.scl-link:active,
a.scl-link:hover,
app-content a:focus,
app-content a:active,
app-content a:hover {
  color: black !important;
}

a.scl-link:active,
app-content a:active {
  color: black !important;
}

.border-radius-0 {
  border-radius: 0px !important;
}

.cdk-overlay-pane {
  margin: auto !important
}

.text-align-center {
  text-align: center;
}


@media (max-width: $screen-mobile-min) {
  body {
    background-color: #1c2126;
    /* sometimes in mobile the home screen gradient is pushed down from the activities details section */
  }
}
