/*
* http://mcg.mbitson.com/#!?cutheme=%232d668e&themename=mcgtheme
* default palettes - https://github.com/angular/components/blob/main/src/material/core/theming/_palette.scss
* For use in src/lib/core/theming/_palette.scss
*/

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$cu-theme-secondary: (
  50 : #f6e4e4,
  100 : #e8bbbb,
  200 : #d98d8d,
  300 : #ca5f5f,
  400 : #be3d3d,
  500 : #b31b1b,
  600 : #ac1818,
  700 : #a31414,
  800 : #9a1010,
  900 : #8b0808,
  A100 : #ffb8b8,
  A200 : #ff8585,
  A400 : #ff5252,
  A700 : #ff3939,
  contrast: (50 : $dark-primary-text,
    100 : $dark-primary-text,
    200 : $dark-primary-text,
    300 : $light-primary-text,
    400 : $light-primary-text,
    500 : $light-primary-text,
    600 : $light-primary-text,
    700 : $light-primary-text,
    800 : $light-primary-text,
    900 : $light-primary-text,
    A100 : $dark-primary-text,
    A200 : $dark-primary-text,
    A400 : $dark-primary-text,
    A700 : $light-primary-text,
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$cu-theme-primary: (
  50 : #e6edf1,
  100 : #c0d1dd,
  200 : #96b3c7,
  300 : #6c94b0,
  400 : #4d7d9f,
  500 : #2d668e,
  600 : #285e86,
  700 : #22537b,
  800 : #1c4971,
  900 : #11385f,
  A100 : #96c6ff,
  A200 : #63aaff,
  A400 : #308fff,
  A700 : #1681ff,
  contrast: (50 : $dark-primary-text,
    100 : $dark-primary-text,
    200 : $dark-primary-text,
    300 : $dark-primary-text,
    400 : $light-primary-text,
    500 : $light-primary-text,
    600 : $light-primary-text,
    700 : $light-primary-text,
    800 : $light-primary-text,
    900 : $light-primary-text,
    A100 : $dark-primary-text,
    A200 : $dark-primary-text,
    A400 : $light-primary-text,
    A700 : $light-primary-text,
  )
);

$cu-theme-dark-primary: (
  // 50 : #EAEDF1,
  // 100 : #DAE0E7,
  // 200 : #ACBAC8,
  // 300 : #7B91A7,
  // 400 : #4B5E71,
  // 500 : #3B4A59,
  // 600 : #2F3A46,
  // 700 : #1F262E,
  // 800 : #141A1F,
  // 900 : #101418,
  // main : #7B91A7,
  50 : #184a77,
  // Slightly lighter
  100 : #235a88,
  // Slightly lighter
  200 : #2d6a99,
  // Slightly lighter
  300 : #377aba,
  // Slightly lighter
  400 : #428acb,
  // Slightly lighter
  500 : #1b1b1b,
  // Much lighter
  600 : #76b6ed,
  // Much lighter
  700 : #90ccfe,
  // Much lighter
  800 : #aad2ff,
  // Very light
  900 : #c4d8ff,
  // Very light
  A100 : #1681ff,
  // Accents can remain the same
  A200 : #308fff,
  // Accents can remain the same
  A400 : #63aaff,
  // Accents can remain the same
  A700 : #96c6ff,
  // Accents can remain the same
  contrast: (50 : $dark-primary-text,
    100 : $dark-primary-text,
    200 : $dark-primary-text,
    300 : $dark-primary-text,
    400 : $dark-primary-text,
    500 : white,
    600 : $dark-primary-text,
    700 : $dark-primary-text,
    800 : $dark-primary-text,
    900 : $dark-primary-text,
    A100 : $dark-primary-text,
    A200 : $dark-primary-text,
    A400 : $dark-primary-text,
    A700 : $dark-primary-text,
  )
);
